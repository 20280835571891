import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import React from "react";
import TopImage from "Images/articles/headers/lutaolimpica.jpg";
import TrainingPage from "./treinos";

export interface LutaolimpicaProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const Lutaolimpica: React.FC<LutaolimpicaProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />

      <div style={{ marginBottom: "-240px", zIndex: 10 }}>
        <TrainingPage
          showTitleBar={false}
          activityToShow="Luta Olímpica"
          isReadmore={true}
          readmore={"#readmore"}
        />
      </div>

      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

Lutaolimpica.defaultProps = {
  titleBar: {
    titleBarText: "Luta Olímpica",
    subtitleBarText: "Treine Luta Olímpica na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  pageSection: {
    topImage: TopImage,
    showTopImage: false,
    pageTitle: "Luta Olímpica e Jogos Olímpicos",
    pageText: (
      <>
        <p>
          A Luta é ao lado da maratona um dos esportes mais antigos de que se
          tem registro. Embora não haja confirmação de uma data precisa,
          acredita-se que a Luta começou a ser praticada no período Micênico da
          Grécia Antiga. Os atletas lutavam nus, onde seus músculos delineados
          representavam o equilíbrio entre corpo e mente. Registros e imagens
          datados do ano de 2000 a.C. foram encontradas com movimentos similares
          aos utilizados nos dias de hoje. A expansão territorial dos romanos é
          apontada com um dos fatores de propagação da luta. Literaturas antigas
          de povos árabes e orientais possuem registros de práticas similares ao
          esporte. Já no século XVIII, atribui-se as tropas de Napoleão
          Bonaparte novas regras e até mesmo a criação do nome greco-romano para
          o esporte. Nas tropas do comandante francês era obrigatório o
          aprendizado da luta.
        </p>

        <h2>Era Antiga</h2>
        <p>
          A Luta estreou nos Jogos Olímpicos da era antiga no ano de 704 a.C.
          Segundo escritos e esculturas da época os atletas lutavam nus e com
          mistura de azeite de oliva e terra no corpo. O objetivo era derrubar o
          adversário três vezes. Era considerado queda quando o oponente tocava
          as costas, o ombro ou o tórax no solo. Não havia limite de tempo.
          Ainda era feita uma divisão de faixa etária, entre rapazes e adultos.
          O esporte permaneceu nos Jogos Olímpicos até o fim dos Jogos Olímpicos
          da antiguidade. Com o domínio do Impero Romano sobre os gregos e o
          posteriormente o fim dos Jogos Olímpicos, a luta permaneceu na cultura
          romana e atravessou os séculos.Fator que explica a adoção do termo
          greco-romano a um dos estilos da luta olímpica. Os Jogos Olímpicos da
          Antiguidade foram extintos por ordem do Imperador Teodósio em 393
          d.C., no entanto, em virtude da extensão do Império Romano, dividido
          entre ocidente e oriente, a disseminação da luta estava mais do que
          garantida.
        </p>

        <h2>Era Moderna</h2>
        <p>
          Em 1896, o Barão de Coubertin inaugurou os Jogos Olímpicos da era
          moderna. A Luta Olímpica foi apontada como um dos elos entre passado e
          o presente. A edição seguinte marcou a única ausência da modalidade no
          cronograma olímpico. Em 1904, nos Jogos de Saint Louis, o estilo livre
          foi disputado pela primeira vez, apenas por atletas americanos. Pela
          primeira vez os atletas foram distribuídos em categorias de peso. Na
          Edição de 1908, os dois estilos passaram a ser disputados e continuam
          até os dias de hoje.
        </p>

        <p>
          As divisões de categoria de peso foram alteradas conforme o tempo. Na
          edição dos Jogos Olímpicos de Munique de 1972 ficou estabelecido o
          número de 10 categorias para cada estilo. Em Sydney 2000, o número
          caiu para 8 categorias de peso por estilo.Os Jogos Olímpicos de Atenas
          promoveram a entrada nas mulheres no esporte. Elas contavam com apenas
          4 categorias olímpicas, enquanto os estilos masculinos tinham sete
          cada. Nos Jogos Olímpicos Rio 2016 pela primeira vez na história as
          categorias serão equiparadas. Estilo livre masculino, estilo
          greco-romano e Luta feminina terão seis categorias cada. No total
          serão 72 medalhas distribuídas.
        </p>

        <p>
          Em 2013, a Luta Olímpica foi indicada pela Comissão Executiva do
          Comitê Olímpico Internacional para deixar de figurar entres os
          esportes olímpicos a partir de 2020. A Federação Internacional de
          Lutas Associadas transformou a apreensão da exclusão em um marco na
          história do esporte. A Luta Olímpica mostrou ao mundo que além da
          tradição secular é um esporte global disputado e apreciado em todos os
          continentes do planeta. O resultado não poderia ter sido outro. Na
          votação realizada em Buenos Aires, o esporte teve o dobro de votos em
          relação aos esportes postulantes ao cronograma.
        </p>

        <p>
          A Luta Olímpica é um dos poucos esportes popular nos quatro cantos do
          planeta. No continente americano Cuba e Estados Unidos são
          considerados potências. Na Europa, Rússia e os países ex-integrantes
          da União Soviética estão sempre nas primeiras colocações. Já no
          Oriente Médio e África,Irã e Egito, respectivamente já conseguiram
          medalhas olímpicas. Na Ásia, China, Coreia do Sul e Mongólia aparecem
          com destaque. Nos Jogos Olímpicos de Atlanta, em 1996, 17 diferentes
          países obtiveram medalhas.
        </p>

        <p>
          Referência: <br />
          <a href="http://cbw.org.br" target="_blank" rel="noreferrer">
            <u>http://cbw.org.br</u>
          </a>
        </p>
      </>
    ),
  },
};

export default Lutaolimpica;
