import React from "react";
import {
  WazeBTN,
  WazeIcon,
  WazeTextBTN,
  WhatsAppBTN,
  WhatsAppIcon,
  WhatsAppTextBtn,
  WhatsAppWrapper,
} from "./WhatsAppWazeButton.styled";
export interface WhatsAppButtonProps {
  wppBtnText?: string;
  wppBtnCTA?: string;
  wazeBtnText?: string;
  wazeBtnCTA?: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = (props) => {
  return (
    <>
      <WhatsAppWrapper>
        <a href={props.wppBtnCTA} target="_blank" rel="noreferrer">
          <WhatsAppBTN>
            <WhatsAppTextBtn>
              <WhatsAppIcon />
              {props.wppBtnText}
            </WhatsAppTextBtn>
          </WhatsAppBTN>
        </a>
        <a href={props.wazeBtnCTA} target="_blank" rel="noreferrer">
          <WazeBTN>
            <WazeTextBTN>
              <WazeIcon />
              {props.wazeBtnText}
            </WazeTextBTN>
          </WazeBTN>
        </a>
      </WhatsAppWrapper>
    </>
  );
};

WhatsAppButton.defaultProps = {
  wppBtnText: "Chame a CMT12 no WhatsApp",
  wppBtnCTA: "https://wa.me/5521964139296",
  wazeBtnText: "Venha pelo Waze",
  wazeBtnCTA: "waze://?ll=-22.8737723,-43.4573967&navigate=yes",
};

export default WhatsAppButton;
