import React from "react";
import { ArticleSection } from "Utils/Styles/App.styled";
import Article, { ArticleProps } from "Components/Article/Article";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import Clayton from "Images/articles/headers/011.jpg";
import Bicudo from "Images/articles/headers/equipe-bicudo.jpg";
import Charuto from "Images/articles/headers/equipe-charuto.jpg";

import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";

export interface EquipPageProps {
  titleBar?: TitleBarProps;
  article1?: ArticleProps;
  article4?: ArticleProps;
  article5?: ArticleProps;
}

const EquipPage: React.FC<EquipPageProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />
      <ArticleSection style={{ marginTop: "250px" }}>
        <Article {...props.article1} />
        <Article {...props.article4} />
        <Article {...props.article5} />
      </ArticleSection>
    </>
  );
};

EquipPage.defaultProps = {
  titleBar: {
    titleBarText: "EQUIPE CMT12",
    subtitleBarText: "Nossa equipe de campeões",
  },
  article1: {
    mainWidth: "700px",
    minHeight: "1130px",
    articleImage: Clayton,
    title: "Clayton Mangueira",
    subtitle: "Mestre e Fundador da CMT12",
    description: (
      <>
        <div
          style={{
            fontSize: "24px",
            display: "flex",
            gap: "5px",
            margin: "-10px 0 ",
          }}
        >
          <a
            href="https://www.facebook.com/claytonmangueira"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="https://www.facebook.com/claytonmangueira"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <FaInstagram />
          </a>

          <a
            href="https://www.facebook.com/claytonmangueira"
            target="_blank"
            rel="noreferrer"
            title="Youtube"
          >
            <FaYoutube />
          </a>
        </div>
        <p>
          Bacharel em Educação Física pela UGF e Pós Graduado em Gestão de
          Negócios em Fitness e Wellness pela Univoa.
          <br />
          Carlos Clayton de Souza, o Clayton Mangueira é Grau Preto de Muay
          Thai, Faixa Preta de Jiu-Jitsu e treinador de diversas modalidades
          como Boxe, Beachboxing, Luta Olímpica, Luta Livre e MMA.
          <br />
          <br />
          Nascido na década de 70 na Maternidade Carmela Dutra, no Rio de
          Janeiro, Mangueira é colecionador de medalhas e bicampeão do ranking
          mundial da IBJJF 2018/2019 na modalidade no-gi (sem kimono) e detém
          diversas outras conquistas.
          <br />
          <br />
          Em sua jornada também pôde contar com participações no cinema e tv,
          como em novelas da Rede Globo, documentário do History Channel e até
          do filme Tropa de Elite.
          <br />
          <br />
          Apelidado de Mangueira pelo Grão Mestre Luiz Alves "In Memoriam"
          sempre que chegava para treinar na academia Budokan, por conta de seu
          projeto social no bairro deste mesmo nome, no Rio de Janeiro, levando
          o esporte para jovens e crianças carentes daquela comunidade.
          <br />
        </p>
      </>
    ),
    readmore: "/clayton-mangueira",
    isTop: false,
    isDescriptionLimit: false,
  },
  article4: {
    mainWidth: "700px",
    minHeight: "1130px",
    articleImage: Bicudo,
    title: "Marcos Bicudo Maciel",
    subtitle: "Nascido na cidade do RJ no ano de 1985 no subúrbio carioca",
    description: (
      <>
        <p>
          Marcos Bicudo Maciel começou a treinar artes marciais com o mestre
          Clayton Mangueira em um projeto social no ano de 1997 seguindo os
          passos do mestre seguiu seus passos em direção a famosa academia
          Budokan aonde iniciou os treinos de Muay Thai e luta livre esportiva.
          <br />
          <br />
          Após Longos anos de treinos formou-se faixa preta de luta livre
          esportiva do renomado Mestre Clayton Mangueira no ano de 2008.
          <br />
          <br />
          Conquistando inúmeros títulos na modalidade luta livre esportiva e
          Nogi, Tri campeão do Naga, campeão nacional Americano ibjjf, campeão
          copa Budokan de luta livre esportiva.
          <br />
        </p>
      </>
    ),
    isTop: false,
    readmore: "",
    isReadmore: false,
    isDescriptionLimit: false,
  },
  article5: {
    mainWidth: "700px",
    minHeight: "1130px",
    articleImage: Charuto,
    title: "Rodrigo Pereira da Silva Charuto",
    subtitle: "Natural do Rio de Janeiro",
    description: (
      <>
        <div
          style={{
            fontSize: "24px",
            display: "flex",
            gap: "5px",
            margin: "-10px 0 ",
          }}
        >
          <a
            href="https://www.facebook.com/charutogcb"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="https://www.instagram.com/charutogcb"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <FaInstagram />
          </a>
        </div>
        <p>
          Nascido em 04 de Junho de 83
          <br />
          Grau preto de Muay-thai pela CMT12
          <br />
          Corda Preta de capoeira Grupo capoeira Brasil
          <br />
        </p>
      </>
    ),
    isTop: false,
    readmore: "",
    isReadmore: false,
    isDescriptionLimit: false,
  },
};

export default EquipPage;
