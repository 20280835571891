import { HoursComponent } from "Utils/Styles/App.styled";
import * as React from "react";

export interface HoursProps {
  hourText?: string;
  trainingType?: "Adult" | "Kids" | "Fem";
}

const Hours: React.FC<HoursProps> = (props) => {
  return (
    <>
      <HoursComponent trainingType={props.trainingType}>
        {props.hourText}
      </HoursComponent>
    </>
  );
};

Hours.defaultProps = {
  hourText: "Segundas: 8h30",
  trainingType: "Adult",
};

export default Hours;
