import { BrowserRouter as Router } from "react-router-dom";
import { Main } from "./Utils/Styles/App.styled";
import Header from "./Components/Header/Header";
import RoutesComponent from "Utils/Routes";
import Footer from "Components/Footer/Footer";
import MobileMenu from "Components/MobileMenu/MobileMenu";

function App() {
  return (
    <>
      <Router>
        <Main>
          <MobileMenu />
          <Header />
          <RoutesComponent />
          <Footer />
        </Main>
      </Router>
    </>
  );
}

export default App;
