import styled from "styled-components";
import * as color from "Utils/Styles/Colors";
import * as icon from "react-icons/fa";
import { up } from "Utils/Helpers/Media";

export const WhatsAppWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-top: 1px solid ${color.blueSolid};
  padding: 40px 0;
  gap: 20px;
`;

export const WazeBTN = styled.div`
  width: 500px;
  height: 90px;
  background: ${color.wazeBlue};
  color: ${color.white};
  border-radius: 15px;
  font-size: 1.3em;
  :hover {
    background: ${color.wazeBlueHover};
    border-radius: 15px;
  }

  ${up("xs")} {
    width: 350px;
  }
`;

export const WazeTextBTN = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
`;

export const WazeIcon = styled(icon.FaWaze)`
  color: ${color.white};
`;

export const WhatsAppBTN = styled.div`
  width: 500px;
  height: 90px;
  background: ${color.whatsappGreen};
  color: ${color.white};
  border-radius: 15px;
  font-size: 1.3em;
  :hover {
    background: ${color.whatsappGreenHover};
    border-radius: 15px;
  }
  ${up("xs")} {
    width: 350px;
  }
`;

export const WhatsAppTextBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
`;

export const WhatsAppIcon = styled(icon.FaWhatsapp)`
  color: white;
`;
