import {
  ArticleDescription,
  ArticleHeader,
  ArticleMain,
  ArticleSubtitle,
  ArticleTitle,
  NotifyIcon,
  ArticleTop,
  ArticleTopIcon,
  ArticleBody,
} from "Utils/Styles/Article";
import React from "react";
import Boxe from "Images/articles/headers/003.jpg";

export interface ArticleProps {
  articleImage?: string;
  title?: string;
  subtitle?: string;
  description?: React.ReactNode;
  readmore?: string;
  toptext?: string;
  iconName?: React.ReactNode;
  isTop?: boolean;
  isReadmore?: boolean;
  isDescriptionLimit?: boolean;
  mainWidth?: string;
  minHeight?: string;
}

const Article: React.FC<ArticleProps> = ({
  articleImage,
  title,
  subtitle,
  description,
  isDescriptionLimit,
  readmore,
  toptext,
  iconName,
  isTop,
  isReadmore,
  mainWidth,
  minHeight,
}) => {
  const truncatedText = description?.toString().slice(0, 182) + "...";

  return (
    <>
      <ArticleMain mainWidth={mainWidth}>
        {isTop ? (
          <ArticleTop>
            <ArticleTopIcon>{iconName}</ArticleTopIcon>
            {toptext}
          </ArticleTop>
        ) : null}
        <ArticleBody minHeight={minHeight}>
          <ArticleHeader articleImage={articleImage} />

          <ArticleTitle>
            <a href={readmore}>{title}</a>
          </ArticleTitle>

          <ArticleSubtitle>{subtitle}</ArticleSubtitle>

          <ArticleDescription>
            {isDescriptionLimit ? truncatedText : description}
            {isReadmore ? (
              <a href={readmore}>
                Veja mais
                <NotifyIcon />
              </a>
            ) : null}
          </ArticleDescription>
        </ArticleBody>
      </ArticleMain>
    </>
  );
};

Article.defaultProps = {
  mainWidth: "600px",
  minHeight: "630px",
  articleImage: Boxe,
  iconName: "icon.FaSchool",
  toptext: "A Escola CMT12",
  title: "Título",
  subtitle: "Subtitulo",
  description: "Descrição",
  readmore: "/sobre",
  isTop: true,
  isReadmore: true,
  isDescriptionLimit: true,
};

export default Article;
