import React, { useState } from "react";
import { ContactFormComponent, FormItem } from "./ContactForm.styled";
import emailjs from "@emailjs/browser";

interface ContactFormProps {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
}

const domainOptions = [
  "gmail.com",
  "hotmail.com",
  "outlook.com",
  "yahoo.com",
  "uol.com.br",
  "terra.com.br",
  "bol.com.br",
  "globo.com",
  "r7.com",
  "protonmail.com",
  "ig.com.br",
  "sercomtel.com.br",
];

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<ContactFormProps>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isMessageSent, setIsMessageSent] = useState(false);

  const [showDomainOptions, setShowDomainOptions] = useState(false);

  const handleChangeMail = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "email") {
      formattedValue = value.toLowerCase();

      const atIndex = formattedValue.indexOf("@");
      if (atIndex !== -1) {
        const domainFragment = formattedValue.slice(atIndex + 1);

        const matchingDomains = domainOptions.filter((domain) =>
          domain.startsWith(domainFragment)
        );

        setShowDomainOptions(matchingDomains.length > 0);
      } else {
        setShowDomainOptions(false);
      }
    }

    setFormData({ ...formData, [name]: formattedValue });
  };

  const handleDomainOptionClick = (domain: string) => {
    const atIndex = formData.email?.indexOf("@");
    const username = formData.email?.slice(0, Number(atIndex) + 1);
    const formattedValue = username + domain;
    setFormData({ ...formData, email: formattedValue });
    setShowDomainOptions(false);
  };

  const handleChangePhone = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "phone") {
      formattedValue = value.replace(/\D/g, "");

      if (formattedValue.length > 0) {
        formattedValue = formattedValue.replace(/^(\d{2})(\d)/g, "$1 $2");
        formattedValue = formattedValue.replace(/(\d)(\d{4})$/, "$1-$2");
      }
    }

    setFormData({ ...formData, [name]: formattedValue });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      message: formData.message,
      email: formData.email,
      phone: formData.phone,
    };

    emailjs.send(
      "service_ix7nns5",
      "template_gf9x2gb",
      templateParams,
      "JIpvnhALRyfFfb4xz"
    );

    console.log(formData);

    setFormData({ name: "", email: "", phone: "", message: "" });

    setIsMessageSent(true);
  };

  return (
    <ContactFormComponent onSubmit={handleSubmit}>
      <FormItem>
        <label htmlFor="name">Nome:</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Nome"
          value={formData.name}
          autoComplete="new-name"
          onChange={handleChangePhone}
          required
          autoFocus
        />
      </FormItem>
      <FormItem>
        <label htmlFor="email">E-mail:</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="E-mail"
          value={formData.email}
          autoComplete="new-email"
          onChange={handleChangeMail}
          required
        />
        {showDomainOptions && (
          <ul>
            {domainOptions.map((domain) => (
              <li key={domain} onClick={() => handleDomainOptionClick(domain)}>
                {domain}
              </li>
            ))}
          </ul>
        )}
      </FormItem>
      <FormItem>
        <label htmlFor="phone">Telefone:</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="XX XXXXX-XXXX"
          maxLength={13}
          value={formData.phone}
          autoComplete="new-phone"
          onChange={handleChangePhone}
          required
        />
      </FormItem>
      <FormItem>
        <label htmlFor="message">Mensagem:</label>
        <textarea
          id="message"
          name="message"
          placeholder="Escreva sua mensagem"
          value={formData.message}
          onChange={handleChangeMail}
          autoComplete="nope"
          required
        />
        <button type="submit">Enviar</button>
        {isMessageSent && <p>Sua mensagem foi enviada.</p>}
      </FormItem>
    </ContactFormComponent>
  );
};

export default ContactForm;
