import { Video, VideoWrapper } from "Utils/Styles/App.styled";
import React from "react";

interface VideoEmbedProps {
  videoId?: string;
  isVideo?: boolean;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ videoId, isVideo }) => {
  return (
    <>
      {isVideo ? (
        <VideoWrapper>
          <Video
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder={0}
          ></Video>
        </VideoWrapper>
      ) : null}
    </>
  );
};

export default VideoEmbed;
