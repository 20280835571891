export const white = "#FFFFFF";
export const black = "#000000";
export const blueSolid = "rgb(0,58,100)";
export const green = "rgb(0,100,00)";
export const darkGreen = "rgb(0,70,00)";
export const whatsappGreenHover = "rgb(18,140,126)";
export const whatsappGreen = "rgb(7,94,84)";
export const wazeBlue = "rgb(5, 200, 247)";
export const wazeText = "rgb(60, 68, 76)";
export const wazeBlueHover = "rgb(76, 132, 148)";
export const pink = "rgb(240,20,200)";
export const blueGradient =
  "radial-gradient(circle, rgba(0,58,100,1) 0%, rgba(0,0,0,1) 100%)";

export const textShadow = `4px 4px 6px ${black}`;
