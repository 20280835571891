import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import React from "react";
import TopImage from "Images/articles/headers/mma.jpg";
import TrainingPage from "./treinos";

export interface MmaProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const Mma: React.FC<MmaProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />

      <div style={{ marginBottom: "-240px", zIndex: 10 }}>
        <TrainingPage
          showTitleBar={false}
          activityToShow="MMA"
          isReadmore={true}
          readmore={"#readmore"}
        />
      </div>

      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

Mma.defaultProps = {
  titleBar: {
    titleBarText: "MMA",
    subtitleBarText: "Treine MMA na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  pageSection: {
    topImage: TopImage,
    showTopImage: false,
    pageTitle: "A História do MMA",
    pageText: (
      <>
        <p>
          Mixed Martial Arts ou, em português, Artes Marciais Mistas
          constituem-se de um tipo de luta oficial em que os atletas que se
          confrontam podem utilizar golpes de mais de uma arte marcial. Em
          geral, é bastante comum que cada atleta tenha a sua especialidade em
          uma modalidade específica. Os embates permitem que a disputa ocorra em
          pé ou no chão, utilizando-se de técnicas específicas do judô, do
          caratê, do jiu-jtsu, do muay-thai, do boxe, do kickboxing e do
          wrestling.
        </p>
        <p>
          A origem desse esporte, nos moldes como o conhecemos, é brasileira.
          Seu início se deu na década de 30, quando o jiu-jitsu, por meio de
          Hélio Gracie, tentava se estabelecer no país como uma arte marcial
          eficiente. Nesse contexto, eram promovidos confrontos entre lutadores
          de outras artes marciais contra especialistas em jiu-jitsu.
          Diferentemente das lutas que são observadas atualmente, nessa época
          não havia regras delimitando os confrontos, o que colocava em risco a
          vida dos lutadores. No entanto, a estratégia dos Gracie rendeu os
          frutos esperados: após vencer inúmeras lutas, o jiu-jitsu passou a
          ganhar popularidade.
        </p>
        <p>
          A primeira organização de um torneio de MMA ocorreu nos Estados
          Unidos, por incentivo de Rórion Grace (filho de Hélio Grace). Trata-se
          do UFC – Ultimate Fight Championship, que se iniciou no ano de 1993, e
          que hoje movimenta cerca de 1,5 bilhão de dólares. Esse campeonato foi
          como alavanca para o surgimento de outros campeonatos que se tornaram
          tão importantes quanto o UFC, como é o caso do Strike Force. O Strike
          Force também apresentou uma inovação de extrema importância para o
          universo do MMA: são organizadas lutas também entre lutadoras
          mulheres, o que não ocorre no UFC.
        </p>
        <p>
          As lutas são compostas por três rounds, de três minutos cada. A
          exceção se dá em decisão de cinturão, composta por cinco rounds, de
          três minutos cada um.
        </p>
        <p>
          As categorias masculinas são geralmente divididas em:
          <p>- Leve: até 70kg;</p>
          <p>- Meio médio: até 77kg;</p>
          <p>- Médio: até 84kg;</p>
          <p>- Meio pesado: até 93kg;</p>
          <p>- Pesado: até 120kg.</p>
        </p>
        <p>
          No que se refere às disputas femininas, elas ocorrem apenas em duas
          categorias: - Meio-médio: até 61kg; - Médio: até 66kg. O Brasil ainda
          permanece com atletas em destaque. Exemplos são Vitor Belfort,
          Anderson Silva e Lioto Machida. Belfort, especialista em jiu-jitsu com
          excelentes conhecimentos de caratê, já foi nove vezes campeão do UFC,
          três vezes campeão do Cage Rage (campeonato em Londres) e cinco vezes
          campeão no torneio japonês Pride. Anderson Silva, faixa preta em
          jiu-jitsu, judô, praijed preto em muay-thai, além de ser cordão
          amarela em capoeira regional, é atual campeão do Cage Rage na
          categoria meio médio e do UFC, na categoria médio. Além disso, bateu o
          recorde de maior número de vitórias consecutivas no UFC; recorde de
          maior número de vitórias consecutivas para defesa do título, na mesma
          competição; e três vezes vencedor da luta da noite, também no UFC.
          Lyoto Machida é faixa preta em jiu-jitsu e em caratê shotokan, e já
          foi campeão da categoria meio pesado.{" "}
        </p>
        <p>
          Referência:
          <br />
          <a
            href="https://brasilescola.uol.com.br/educacao-fisica/mma-mixed-martial-arts.htm"
            target="_blank"
            rel="noreferrer"
          >
            <u>
              https://brasilescola.uol.com.br/educacao-fisica/mma-mixed-martial-arts.htm
            </u>
          </a>
        </p>
      </>
    ),
  },
};

export default Mma;
