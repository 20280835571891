import styled from "styled-components";
import * as color from "./Colors";
import * as Space from "Utils/Styles/Spaces";
import { PageSectionProps } from "Components/PageElements/PageSection";
import { HoursProps } from "Components/Hours/Hours";
import BGHero from "Images/bgHero.jpg";
import BGHero2 from "Images/bgHero2.jpg";
import { TitleBarProps } from "Components/PageElements/PageTitleBar";
import { up } from "Utils/Helpers/Media";
export const Main = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background: ${color.blueSolid};
  background: ${color.blueGradient};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${color.white};

  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    color: ${color.blueSolid};
  }

  ${up("xs")} {
  }
`;

export const Hero = styled.div`
  width: 100%;
  height: calc(100vh - 100px);

  ${up("xxs")} {
    background-image: url(${BGHero2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: 0;
  }

  ${up("md")} {
    background-image: url(${BGHero});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }
  ${up("xl")} {
    margin-top: 100px;
  }
`;

export const ArticleSection = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding: 30px ${Space.BodyPaddingLeft};
  z-index: 9999;

  ${up("xs")} {
    width: 90vw;
    padding: 30px 5px;
  }
`;

export const TrainingToHome = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
`;

export const PageTitleBar = styled.div<TitleBarProps>`
  width: 100%;
  height: 140px;
  z-index: 999;
  position: relative;
  margin-top: 110px;

  ${(props) =>
    props.showBackground === true &&
    `
    background: ${color.blueGradient};
    margin-bottom: -245px;
    ${up("xs")} {
      text-align: center;
      width: 100vw;
    }
  `}

  ${(props) =>
    props.showBackground === false &&
    `
    position: absolute;
    background-color: transparent;
    bottom: 30px;

    ${up("xs")} {
      padding: 0 20px;
      text-align: center;
      width: 90%;
    }
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageTileBarText = styled.div<TitleBarProps>`
  font-size: 2em;

  ${(props) =>
    props.showBackground === false &&
    `
    font-size: 3em;
    
    `}
  font-weight: bold;
  text-shadow: ${color.textShadow};
  color: ${color.white};
`;

export const PageTitleBarSubtitle = styled.div<TitleBarProps>`
  font-size: 1em;
  margin: 0 2%;
  ${(props) =>
    props.showBackground === false &&
    `
    font-size: 1.5em;
    `}

  font-weight: normal;
  color: ${color.white};
  text-shadow: ${color.textShadow};
`;

export const HeroTitleBar = styled.div<TitleBarProps>`
  width: 100%;
  height: 140px;
  z-index: 999;
  position: relative;
  margin-top: 110px;

  ${(props) =>
    props.showBackground === true &&
    `
    background: ${color.blueGradient};
    margin-bottom: -245px;
    ${up("xs")} {
      text-align: center;
      width: 100%;
    }
  `}

  ${(props) =>
    props.showBackground === false &&
    `
    position: absolute;
    background-color: transparent;
    bottom: 30px;

    ${up("xs")} {
      padding: 0 20px;
      text-align: center;
      width: 90%;
    }
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeroTileBarText = styled.div<TitleBarProps>`
  font-size: 2em;

  ${up("xxs")} {
    margin-top: -150px;
  }

  ${up("xl")} {
    margin: 0;
  }

  ${(props) =>
    props.showBackground === false &&
    `
    font-size: 3em;
    
    `}
  font-weight: bold;
  text-shadow: ${color.textShadow};
  color: ${color.white};
`;

export const HeroTitleBarSubtitle = styled.div<TitleBarProps>`
  font-size: 1em;
  ${(props) =>
    props.showBackground === false &&
    `
    font-size: 1.5em;
    `}

  font-weight: normal;
  color: ${color.white};
  text-shadow: ${color.textShadow};
`;

export const PageSectionBody = styled.div<PageSectionProps>`
  /* width: calc(100% - ${Space.BodyPaddingLeft} - ${Space.BodyPaddingRight}); */
  width: 90%;

  ${(props) =>
    props.showWhiteBackground === true &&
    `
    background-color: white;
  `}

  ${(props) =>
    props.showWhiteBackground === false &&
    `
    background-color: transparent;
  `}
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 250px;
`;

export const PageSectionHeaderImage = styled.div<PageSectionProps>`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-image: ${(props) => `url(${props.topImage})`};
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  ${up("xxs")} {
    margin: 0 2.5%;
    min-height: 500px;
  }

  ${up("xl")} {
    min-height: 900px;
    margin-left: ${Space.BodyPaddingLeft};
    margin-right: ${Space.BodyPaddingRight};
  }
`;

export const PageSectionTitle = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  color: ${color.black};
  padding-top: 30px;

  ${up("xxs")} {
    margin: 0 2.5%;
  }

  ${up("xl")} {
    margin-left: ${Space.BodyPaddingLeft};
    margin-right: ${Space.BodyPaddingRight};
  }
`;

export const PageSectionText = styled.div`
  margin-bottom: 30px;
  font-size: 1em;
  font-weight: normal;
  color: ${color.black};
  justify-content: center;

  ${up("xxs")} {
    margin: 0 2.5%;
  }

  ${up("xl")} {
    min-height: 900px;
    margin-left: ${Space.BodyPaddingLeft};
    margin-right: ${Space.BodyPaddingRight};
  }
`;

export const VideoEmbedSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;

  ${up("xxs")} {
    padding-top: 56.25%;
  }

  ${up("md")} {
    max-width: 30vw;
    padding-top: 23.25%;
  }
`;

export const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${color.blueGradient};
`;

export const HourMain = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: -15px;
`;

export const HoursComponent = styled.button<HoursProps>`
  border-radius: 10px;
  padding: 10px 12px;
  border: none;
  color: ${color.white};
  font-family: inherit;
  font-size: inherit;
  ${(props) =>
    props.trainingType === "Adult" &&
    `
    background-color: ${color.blueSolid};
  `}

  ${(props) =>
    props.trainingType === "Kids" &&
    `
    background-color: ${color.green};
  `}

  ${(props) =>
    props.trainingType === "Fem" &&
    `
    background-color: ${color.pink};
  `}
`;
