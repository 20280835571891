import React, { useState, useEffect } from "react";
import {
  FooterFacebook,
  FooterInstagram,
  FooterSocialIcons,
  FooterText,
  FooterWrapper,
  FooterYoutube,
} from "./Footer.styled";

const Footer: React.FC = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const interval = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== currentYear) {
        setCurrentYear(newYear);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [currentYear]);
  return (
    <>
      <FooterWrapper>
        <FooterSocialIcons>
          <a
            href="https://www.instagram.com/cmt12escoladelutas"
            target="_blank"
            rel="noreferrer"
          >
            <FooterInstagram />
          </a>
          <a
            href="https://www.facebook.com/claytonmangueira12"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <FooterFacebook />
          </a>
          <a
            href="https://www.youtube.com/channel/UCT8vyVTRJJskPAs2VxV5A1Q/videos"
            target="_blank"
            rel="noreferrer"
          >
            <FooterYoutube />
          </a>
        </FooterSocialIcons>
        <FooterText>
          <p>
            <b>Clayton Mangueira Team | {currentYear}</b>
          </p>
          <p>
            CMT12 Sede | Rua Istambul, 117 - Academia i9 FIT em frente à praça
            Guilherme da Silveira, Rio de Janeiro / RJ
          </p>
          <p>
            Desenvolvido por{" "}
            <a
              href="https://www.linkedin.com/in/leeoreis/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Leonardo Reis</b>
            </a>
          </p>
        </FooterText>
      </FooterWrapper>
    </>
  );
};

export default Footer;
