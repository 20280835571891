import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import React from "react";
import TopImage from "Images/articles/headers/boxe.jpg";
import TrainingPage from "./treinos";

export interface BoxeProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const Boxe: React.FC<BoxeProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />

      <div style={{ marginBottom: "-240px", zIndex: 10 }}>
        <TrainingPage
          showTitleBar={false}
          activityToShow="Boxe"
          isReadmore={true}
          readmore={"#readmore"}
        />
      </div>

      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

Boxe.defaultProps = {
  titleBar: {
    titleBarText: "Boxe",
    subtitleBarText: "Treine Boxe na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  pageSection: {
    showTopImage: false,
    topImage: TopImage,
    pageTitle: "A História do Boxe",
    pageText: (
      <>
        O boxe é tradicionalmente um tipo de luta que tem como principal
        característica o combate “homem a homem” utilizando-se apenas dos
        punhos. Por ser um meio de combate, o boxe apenas passou a integrar o
        calendário moderno dos Jogos Olímpicos em 1920, na Olimpíada de
        Antuérpia (Bélgica). O Comitê Olímpico Internacional acreditava que o
        boxe era uma prática que incitava a violência, fato que contrariava o
        ideal de fraternidade, mote estreitamente vinculado ao espírito
        olímpico.
        <br />
        <br />
        Deve-se ressaltar que o boxe é uma luta bastante tradicional no
        Ocidente. Há relatos de que ela era praticada entre jovens, na Creta
        Antiga. Outro elemento a ser considerado são os indicativos de que o
        boxe integrou os Jogos Olímpicos da Antiguidade. Porém, tratando de um
        período mais recente, foi na Inglaterra dos séculos XVIII e XIX que o
        boxe ficou bastante popular: era um combate de rua, lutado com as mãos
        desprotegidas, marcado pela violência dos golpes. A versão moderna do
        boxe foi oficializada em 1867, porém foram colocadas efetivamente em
        prática apenas em 1872, com as regras de Queensberry: o uso de luvas era
        obrigatório e o confronto era composto de rounds de três minutos cada.
        Atualmente, uma luta de boxe é constituída de dez rounds. Em alguns
        casos excepcionais, a partida pode ter até doze.
        <br />
        <br />
        Uma curiosidade é que, mesmo sob o domínio da Associação Mundial de
        Boxe, as regras não são as mesmas entre as competições amadoras e
        profissionais. Variam, inclusive, entre as diferentes comissões
        organizadoras profissionais. Um fato muito comum é o de antes de uma
        grande luta, as regras gerais e particulares são expostas em uma reunião
        entre as duas partes que entrarão em confronto. As organizadoras da luta
        também decidem sobre o tamanho do ringue, mas sua estrutura deve ser
        sempre a mesma em todas as lutas: trata-se de uma plataforma quadrada
        elevada com uma superfície de lona acolchoada. Em todo caso, a área
        máxima de um ringue deve ser de 6,10 metros quadrados.
        <br />
        <br />
        <b>Os principais golpes do boxe serão descritos a seguir:</b>
        <br />
        <br />
        1) Direto: Golpe muito veloz, dado frontalmente pelo punho que se
        localiza atrás da guarda;
        <br />
        2) Cruzado: Visa sempre a lateral da cabeça. Também é forte e veloz,
        como o direto;
        <br />
        3) Jabe: Golpe comumente utilizado para manter a distância entre os
        lutadores, é caracterizado por ser um golpe frontal com o punho
        localizado à frente da guarda;
        <br />
        4) Gancho: Movimento curvo de punho que atinge lateralmente o
        adversário;
        <br />
        5) Uppercut: Objetiva atingir o queixo do adversário, e por isso tem a
        direção de subida: o golpe é dado de baixo para cima.
        <br />
        <br />
        A luta pode ser ganha pela quantidade de round vencidos ou por nocaute
        “knock out”. O nocaute ocorre quando um lutador que recebeu o golpe fica
        visivelmente sem condições de continuar a luta. A decisão é sempre
        tomada pelo juiz do confronto.
        <br />
        <br />
        Algumas ações são impedidas por normas gerais. São elas: golpear o
        adversário abaixo da cintura; chutar; atacar o adversário quando ele
        estiver caído; morder a orelha; atacar com a parte interna da mão,
        antebraço ou cotovelos; agarrar-se nas cordas de limite; agarrar o
        adversário em excesso.
        <br />
        <br />
        Referência:
        <br />
        <a
          href="https://brasilescola.uol.com.br/educacao-fisica/boxe.htm"
          target="_blank"
          rel="noreferrer"
        >
          <u>https://brasilescola.uol.com.br/educacao-fisica/boxe.htm</u>
        </a>
      </>
    ),
  },
};

export default Boxe;
