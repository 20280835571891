import styled from "styled-components";
import { ArticleProps } from "Components/Article/Article";
import * as color from "Utils/Styles/Colors";
import * as icon from "react-icons/fa";

export const ArticleMain = styled.div<ArticleProps>`
  width: ${(props) => props.mainWidth};
  color: #2d2d2d;
`;

export const ArticleTop = styled.div`
  width: 100%;
  height: 60px;
  background: ${color.blueSolid};
  color: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export const ArticleBody = styled.div<ArticleProps>`
  border-radius: 10px;
  width: 100%;
  min-height: ${(props) => props.minHeight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${color.white};
`;

export const ArticleTopIcon = styled.div<ArticleProps>`
  color: ${color.white};
  font-size: 42px;
  margin-top: 3px;
`;

export const ArticleHeader = styled.div<ArticleProps>`
  width: 100%;
  height: 425px;
  background-image: ${(props) => `url(${props.articleImage})`};
  background-repeat: no-repeat;
  background-position: top;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const ArticleTitle = styled.div`
  width: 90%;
  padding: 10px 5%;
  font-size: 1.6em;
  font-weight: bold;
`;

export const ArticleSubtitle = styled.div`
  width: 90%;
  padding: 10px 5%;
  font-size: 1.1em;
`;

export const ArticleDescription = styled.div`
  width: 90%;
  padding: 0 5%;
  color: #2d2d2d;
  font-size: 1.1em;
  margin-bottom: 10px;

  a {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 7px;
    color: ${color.blueSolid};
    font-weight: bold;
    margin-top: 10px;
  }
`;

export const NotifyIcon = styled(icon.FaExclamationCircle)`
  color: ${color.blueSolid};
  font-size: 20px;
`;
