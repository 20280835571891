import styled from "styled-components";
import * as color from "Utils/Styles/Colors";
import * as icon from "react-icons/fa";
import { up } from "Utils/Helpers/Media";

export const FooterWrapper = styled.div`
  width: 100%;
  height: 200px;
  background: ${color.blueGradient};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 20px 0;
`;

export const FooterSocialIcons = styled.div`
  display: flex;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.4);
  gap: 45px;
  :hover {
    color: rgba(255, 255, 255, 1);
  }
`;

export const FooterFacebook = styled(icon.FaFacebook)``;

export const FooterInstagram = styled(icon.FaInstagram)``;

export const FooterYoutube = styled(icon.FaYoutube)``;

export const FooterText = styled.div`
  text-align: center;
  a:hover {
    font-weight: bold;
    color: ${color.white};
  }
  ${up("xs")} {
    padding: 0 30px;
  }
`;
