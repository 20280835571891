import React, { useState, useRef, useEffect } from "react";
import {
  HeaderMain,
  HeaderUL,
  HeaderLI,
  HeaderLeft,
  HeaderRight,
  Submenu,
  MenuItem,
  SubmenuArrowRight,
  SubmenuArrowDown,
} from "../../Utils/Styles/Header.styled";
import LogoH from "Images/logo-topo-bco.png";

export interface HeaderProps {
  isOpen?: boolean;
}

const Header: React.FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const submenuRef = useRef<HTMLUListElement | null>(null);

  const handleToggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      submenuRef.current &&
      !submenuRef.current.contains(event.target as Node)
    ) {
      setIsSubMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <HeaderMain>
        <HeaderLeft>
          <a href="/">
            <img src={LogoH} alt="CMT12 Logo" />
          </a>
        </HeaderLeft>
        <HeaderRight>
          <HeaderUL>
            <HeaderLI>
              <a href="/">Home</a>
            </HeaderLI>
            <HeaderLI>
              <a href="/sobre">Sobre</a>
            </HeaderLI>
            <HeaderLI>
              <a href="/clayton-mangueira">Clayton Mangueira</a>
            </HeaderLI>
            <HeaderLI>
              <a href="/equipe">Nossa Equipe</a>
            </HeaderLI>
            <HeaderLI>
              <a href="/braco-social">Braço Social</a>
            </HeaderLI>

            <HeaderLI onClick={handleToggleSubMenu}>
              Treinos
              {isSubMenuOpen ? <SubmenuArrowRight /> : <SubmenuArrowDown />}
              {isSubMenuOpen && (
                <Submenu ref={submenuRef} isOpen={isSubMenuOpen}>
                  <a href="/treinos/boxe">
                    <MenuItem>Boxe</MenuItem>
                  </a>
                  <a href="/treinos/jiujitsu">
                    <MenuItem>Jiu Jitsu</MenuItem>
                  </a>
                  <a href="/treinos/muaythai">
                    <MenuItem>Muay Thai</MenuItem>
                  </a>
                  <a href="/treinos/lutalivre">
                    <MenuItem>Luta Livre</MenuItem>
                  </a>
                  <a href="/treinos/mma">
                    <MenuItem>MMA</MenuItem>
                  </a>
                  <a href="/treinos/lutaolimpica">
                    <MenuItem>Luta Olímpica</MenuItem>
                  </a>
                </Submenu>
              )}
            </HeaderLI>
            <HeaderLI>
              <a href="/contato">Contato</a>
            </HeaderLI>
          </HeaderUL>
        </HeaderRight>
      </HeaderMain>
    </>
  );
};

export default Header;
