import React from "react";
import {
  PageSectionBody,
  PageSectionHeaderImage,
  PageSectionText,
  PageSectionTitle,
} from "Utils/Styles/App.styled";
import TopImage from "Images/articles/headers/003.jpg";

export interface PageSectionProps {
  topImage?: string;
  showTopImage?: boolean;
  pageTitle?: string;
  pageText?: React.ReactNode;
  showWhiteBackground?: boolean;
}

const PageSection: React.FC<PageSectionProps> = ({
  topImage,
  showTopImage,
  showWhiteBackground,
  pageTitle,
  pageText,
}) => {
  return (
    <PageSectionBody showWhiteBackground={showWhiteBackground}>
      {showTopImage && <PageSectionHeaderImage topImage={topImage} />}
      <div id="readmore">
        <PageSectionTitle>{pageTitle}</PageSectionTitle>
        <PageSectionText>{pageText}</PageSectionText>
      </div>
    </PageSectionBody>
  );
};

PageSection.defaultProps = {
  topImage: TopImage,
  showTopImage: true,
  showWhiteBackground: true,
  pageTitle: "Título da Página",
  pageText: (
    <>
      <p>
        A Clayton Mangueira Team é a melhor escola de lutas da região.
        <br />
        A CMT12 foi criada pelo Mestre Clayton Mangueira. Na CMT12 você aprende
        e mantém seu condicionamento físico no Boxe, Muay Thai, MMA, Luta Livre,
        Luta Olímpica e Jiu Jitsu.
        <br />
        Escola de lutas em Bangu, Rio de Janeiro. Situada na praça Guilherme da
        Silveira, em frente à estação de trem de nome homônimo.
        <br />
        <br />
        O ano é 1998, Clayton Mangueira agora se torna Grau Preto de Muay Thai -
        12º Kahn Sib Song - ano também de sua conquista da Copa BUDOKAN de Luta
        Livre.
        <br />
        <br />
        E é percebendo sua grande expertise em aulas das modalidades Muay Thai e
        Luta Livre, que Clayton decide inaugurar seu projeto "Clayton Mangueira
        Team - CMT12". Em pouco tempo, seus muitos atletas começaram a
        participar de competições de Muay Thai da Confederação Brasileira e
        também da Liga Brasileira.
        <br />
        <br />
        Na LUTA LIVRE, a CMT12 participou de diversas competições como Copa
        BUDOKAN, Copa Cyclone, Copa Rip Dorey, Campeonatos Estaduais,
        Brasileiros, NAGA – North America Grappling Association e ADCC - Abu
        Dhabi Combat Club Submission Wrestling World Championship - por
        exemplos.
        <br />
        <br />
        A equipe migrou também para competições de Vale Tudo (MMA - Mixed
        Martial Arts) e participou de inúmeros eventos não só brasileiros como
        mundiais!
        <br />
        <br />
        Participação expressiva em grandes eventos como: IVC - International
        Vale Tudo Championship, Jungle Fight Championship, Face to Face, WFE,
        WOCS, Fury Fight, Importal, Shooto, Rings, Fights Night, M-1 e Akhamat,
        LS-70 (Rússia), Arena Fight (Argentina), Pride (Japão), UFC além de
        inúmeros títulos nacionais e internacionais em Luta Olímpica.
        <br />
        <br />
        Nos dias atuais a equipe vem participando de competições de Boxe e
        Beachboxing e já vem conquistando vitórias importantes.
        <br />
        <br />O nosso lema é: <b>"A oportunidade faz o campeão".</b>
        <br />
      </p>
    </>
  ),
};

export default PageSection;
