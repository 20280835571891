import ContactForm from "Components/Form/ContactForm/ContactForm";
import GoogleMapsEmbed from "Components/GoogleMapsEmbed/GoogleMapsEmbed";
import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import WhatsAppWazeButton from "Components/WhatsAppWazeButton/WhatsAppWazeButton";
import TopImage from "Images/contactImage.jpg";
import React from "react";

export interface ContactPageProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const ContactPage: React.FC<ContactPageProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />
      <PageSection {...props.pageSection}></PageSection>
      <GoogleMapsEmbed />
    </>
  );
};

ContactPage.defaultProps = {
  titleBar: {
    titleBarText: "CONTATO",
    subtitleBarText: "Envie-nos uma mensagem!",
  },
  pageSection: {
    topImage: TopImage,
    showTopImage: false,
    showWhiteBackground: false,
    pageTitle: "",
    pageText: (
      <>
        <ContactForm />
        <WhatsAppWazeButton />
      </>
    ),
  },
};

export default ContactPage;
