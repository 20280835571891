import { ArticleSection, HourMain } from "Utils/Styles/App.styled";
import Article, { ArticleProps } from "Components/Article/Article";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import Boxe from "Images/articles/headers/boxe.jpg";
import Mma from "Images/articles/headers/mma.jpg";
import MuayThai from "Images/articles/headers/muaythai.jpg";
import JiuJitsu from "Images/articles/headers/jiujitsu.jpg";
import LutaLivre from "Images/articles/headers/lutalivre.jpg";
import LutaOlimpica from "Images/articles/headers/lutaolimpica.jpg";
import React from "react";
import Hours from "Components/Hours/Hours";

export interface TrainingPageProps {
  showTitleBar?: boolean;
  titleBar?: TitleBarProps;
  article1?: ArticleProps;
  article2?: ArticleProps;
  article3?: ArticleProps;
  article4?: ArticleProps;
  article5?: ArticleProps;
  article6?: ArticleProps;
  activityToShow?:
    | "All"
    | "Boxe"
    | "Jiu Jitsu"
    | "Muay Thai"
    | "Luta Livre"
    | "MMA"
    | "Luta Olímpica";
  isReadmore?: boolean;
  readmore?: string;
}

const TrainingPage: React.FC<TrainingPageProps> = (props) => {
  return (
    <>
      {props.showTitleBar && <TitleBarPage {...props.titleBar} />}
      <ArticleSection style={{ marginTop: "250px" }}>
        {(props.activityToShow === "Boxe" ||
          props.activityToShow === "All") && (
          <Article
            {...props.article1}
            isReadmore={props.isReadmore}
            // readmore={props.readmore}
          />
        )}

        {(props.activityToShow === "Jiu Jitsu" ||
          props.activityToShow === "All") && (
          <Article
            {...props.article2}
            isReadmore={props.isReadmore}
            // readmore={props.readmore}
          />
        )}

        {(props.activityToShow === "Muay Thai" ||
          props.activityToShow === "All") && (
          <Article
            {...props.article3}
            isReadmore={props.isReadmore}
            // readmore={props.readmore}
          />
        )}

        {(props.activityToShow === "Luta Livre" ||
          props.activityToShow === "All") && (
          <Article
            {...props.article4}
            isReadmore={props.isReadmore}
            // readmore={props.readmore}
          />
        )}

        {(props.activityToShow === "MMA" || props.activityToShow === "All") && (
          <Article
            {...props.article5}
            isReadmore={props.isReadmore}
            // readmore={props.readmore}
          />
        )}

        {(props.activityToShow === "Luta Olímpica" ||
          props.activityToShow === "All") && (
          <Article
            {...props.article6}
            isReadmore={props.isReadmore}
            // readmore={props.readmore}
          />
        )}
      </ArticleSection>
    </>
  );
};

TrainingPage.defaultProps = {
  activityToShow: "All",
  titleBar: {
    titleBarText: "Nossos Treinos",
    subtitleBarText: "Treine na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  article1: {
    mainWidth: "600px",
    minHeight: "800px",
    articleImage: Boxe,
    title: "Boxe",
    subtitle: "",
    description: (
      <>
        <HourMain>
          <Hours trainingType={"Adult"} hourText={"Segundas: 8h30"} />
          <Hours trainingType={"Adult"} hourText={"Terças: 18h"} />
          <Hours trainingType={"Adult"} hourText={"Quartas: 8h e 19h"} />
          <Hours trainingType={"Adult"} hourText={"Quintas: 18h"} />
        </HourMain>
        <p>
          O boxe é tradicionalmente um tipo de luta que tem como principal
          característica o combate “homem a homem” utilizando-se apenas dos
          punhos. Por ser um meio de combate, o boxe apenas passou a integrar o
          calendário moderno dos Jogos Olímpicos em 1920, na Olimpíada de
          Antuérpia (Bélgica). O Comitê...
        </p>
      </>
    ),
    readmore: "/treinos/boxe",
    isReadmore: true,
    isTop: false,
    isDescriptionLimit: false,
  },
  article2: {
    mainWidth: "600px",
    minHeight: "800px",
    articleImage: JiuJitsu,
    title: "Jiu Jitsu",
    subtitle: "",
    description: (
      <>
        <HourMain>
          <Hours trainingType={"Adult"} hourText={"Terças: 20h"} />
          <Hours trainingType={"Kids"} hourText={"Terças: 19h (kids)"} />
          <Hours trainingType={"Adult"} hourText={"Quintas: 20h"} />
          <Hours trainingType={"Kids"} hourText={"Quintas: 19h (kids)"} />
        </HourMain>
        <p>
          Você sabe o que é jiu jitsu? Se você pensou que jiu jitsu é uma luta
          praticada por pessoas que têm um pitbull de estimação e que adoram
          arrumar briga na rua, muito bem: você errou! Para começarmos a
          compreender essa luta, podemos tomar a tradução imediata do termo jiu
          jitsu, que seria “caminho suave”...
        </p>
      </>
    ),
    readmore: "/treinos/jiujitsu",
    isReadmore: true,
    isTop: false,
    isDescriptionLimit: false,
  },
  article3: {
    mainWidth: "600px",
    minHeight: "800px",
    articleImage: MuayThai,
    title: "Muay Thai",
    subtitle: "",
    description: (
      <>
        <HourMain>
          <Hours trainingType={"Adult"} hourText={"Segundas: 18h e 19h"} />
          <Hours trainingType={"Adult"} hourText={"Terças: 8h e 19h"} />
          <Hours trainingType={"Kids"} hourText={"Terças: 18h (Kids)"} />
          <Hours trainingType={"Adult"} hourText={"Quartas: 18h"} />
          <Hours trainingType={"Adult"} hourText={"Quintas: 8h e 19h"} />
          <Hours trainingType={"Kids"} hourText={"Quintas: 18h (kids)"} />
          <Hours trainingType={"Adult"} hourText={"Sextas: 8h30, 18h e 19h"} />
        </HourMain>
        <p>
          O Muay Thai, também conhecido como boxe Tailandês, é um esporte
          originado na Tailândia, com mais de 1000 anos de existência. O esporte
          teve muita popularidade, pois na época em que começou teve o apoio do
          rei da Tailândia, conhecido como Tigre, o mesmo foi um dos maiores
          lutadores...
        </p>
      </>
    ),
    readmore: "/treinos/muaythai",
    isReadmore: true,
    isTop: false,
    isDescriptionLimit: false,
  },
  article4: {
    mainWidth: "600px",
    minHeight: "800px",
    articleImage: LutaLivre,
    title: "Luta Livre",
    subtitle: "",
    description: (
      <>
        <HourMain>
          <Hours trainingType={"Adult"} hourText={"Segundas: 20h"} />
          <Hours trainingType={"Adult"} hourText={"Quartas: 20h"} />
        </HourMain>
        <p>
          Todas as histórias que envolvem as origens das diversas Artes Marciais
          e Lutas (antigas), curiosamente se iniciam da mesma maneira. Assim,
          dizem os textos: “A luta livre, a mais antiga de todas” Aparecendo
          sempre um período de clandestinidade...
        </p>
      </>
    ),
    readmore: "/treinos/lutalivre",
    isReadmore: true,
    isTop: false,
    isDescriptionLimit: false,
  },
  article5: {
    mainWidth: "600px",
    minHeight: "700px",
    articleImage: Mma,
    title: "MMA",
    subtitle: "",
    description: (
      <>
        <p>
          Mixed Martial Arts ou, em português, Artes Marciais Mistas
          constituem-se de um tipo de luta oficial em que os atletas que se
          confrontam podem utilizar golpes de mais de uma arte marcial. Em
          geral, é bastante comum que cada atleta tenha a sua especialidade em
          uma modalidade específica...
        </p>
      </>
    ),
    readmore: "/treinos/mma",
    isReadmore: true,
    isTop: false,
    isDescriptionLimit: false,
  },
  article6: {
    mainWidth: "600px",
    minHeight: "700px",
    articleImage: LutaOlimpica,
    title: "Luta Olimpica",
    subtitle: "",
    description: (
      <>
        <p>
          A Luta é ao lado da maratona um dos esportes mais antigos de que se
          tem registro. Embora não haja confirmação de uma data precisa,
          acredita-se que a Luta começou a ser praticada no período Micênico da
          Grécia Antiga...
        </p>
      </>
    ),
    readmore: "/treinos/lutaolimpica",
    isReadmore: true,
    isTop: false,
    isDescriptionLimit: false,
  },
};

export default TrainingPage;
