import React from "react";

const GoogleMapsEmbed = () => {
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3676.0694428761053!2d-43.457468!3d-22.873893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bdf88b4a291b1%3A0x4853b1a970dcb33b!2sCMT12%20SEDE%20-%20Escola%20de%20Lutas!5e0!3m2!1spt-BR!2sbr!4v1689607242756!5m2!1spt-BR!2sbr"
      width="100%"
      height={500}
      style={{ border: 0, marginBottom: "-50px" }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default GoogleMapsEmbed;
