import {
  HeroTitleBar,
  HeroTileBarText,
  HeroTitleBarSubtitle,
  PageTitleBar,
  PageTileBarText,
  PageTitleBarSubtitle,
} from "Utils/Styles/App.styled";
import React from "react";

export interface TitleBarProps {
  titleBarText?: string;
  subtitleBarText?: string;
  showBackground?: boolean;
  showTitleBar?: boolean;
}

const TitleBarPage: React.FC<TitleBarProps> = ({
  titleBarText,
  subtitleBarText,
  showBackground,
  showTitleBar,
}) => {
  return (
    <>
      {showTitleBar && !showBackground ? (
        <HeroTitleBar showBackground={showBackground}>
          <HeroTileBarText showBackground={showBackground}>
            {titleBarText}
          </HeroTileBarText>
          <HeroTitleBarSubtitle showBackground={showBackground}>
            {subtitleBarText}
          </HeroTitleBarSubtitle>
        </HeroTitleBar>
      ) : (
        <PageTitleBar showBackground={showBackground}>
          <PageTileBarText showBackground={showBackground}>
            {titleBarText}
          </PageTileBarText>
          <PageTitleBarSubtitle showBackground={showBackground}>
            {subtitleBarText}
          </PageTitleBarSubtitle>
        </PageTitleBar>
      )}
    </>
  );
};

TitleBarPage.defaultProps = {
  titleBarText: "CLAYTON MANGUEIRA TEAM",
  subtitleBarText: "A História da Escola de Lutas CMT12",
  showBackground: true,
  showTitleBar: true,
};

export default TitleBarPage;
