import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import React from "react";
import TopImage from "Images/articles/headers/jiujitsu.jpg";
import TrainingPage from "./treinos";

export interface JiuJitsuProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const JiuJitsu: React.FC<JiuJitsuProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />

      <div style={{ marginBottom: "-240px", zIndex: 10 }}>
        <TrainingPage
          showTitleBar={false}
          activityToShow="Jiu Jitsu"
          isReadmore={true}
          readmore={"#readmore"}
        />
      </div>

      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

JiuJitsu.defaultProps = {
  titleBar: {
    titleBarText: "Jiu Jitsu",
    subtitleBarText: "Treine Jiu Jitsu na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  pageSection: {
    showTopImage: false,
    topImage: TopImage,
    pageTitle: "A História do Jiu Jitsu",
    pageText: (
      <>
        <p>
          Você sabe o que é jiu jitsu? Se você pensou que jiu jitsu é uma luta
          praticada por pessoas que têm um pitbull de estimação e que adoram
          arrumar briga na rua, muito bem: você errou! Para começarmos a
          compreender essa luta, podemos tomar a tradução imediata do termo jiu
          jitsu, que seria “caminho suave”. Isso se deve aos seus princípios
          básicos, uma vez que essa prática privilegia o equilíbrio e o sistema
          de alavancas do corpo humano em detrimento do uso da força e das
          armas. Ou seja, uma luta que prioriza a consciência corporal ao invés
          da força, não pode ser associada às arruaças de rua.
        </p>
        <p>
          A história do jiu jitsu remete à Índia. Acredita-se que essa técnica
          teria sido criada por monges budistas, que buscavam um método de
          defesa pessoal. Conforme o budismo teria se expandido, o jiu jitsu
          também o teria. Assim, sua prática e ensinamentos passaram pela China
          até atingir o Japão, local onde a técnica foi popularizada. No século
          XIX, alguns mestres se dispersaram do Japão e um deles veio se fixar
          em Belém do Pará, em 1915, onde conheceu e criou vínculo com a família
          Gracie. Aí reside uma coincidência que modificaria o rumo dessa arte
          marcial. Gastão Gracie era pai de oito filhos, e incentivou o mais
          velho a aprender a arte com o mestre Esai Maeda Koma.
        </p>
        <p>
          Carlos Gracie, já detentor da arte marcial do jiu jitsu, mudou-se de
          Belém para o Rio de Janeiro aos dezenove anos de idade, com o intuito
          de lutar e de ensinar essa luta. Obteve tamanho sucesso ao ponto de
          modificar alguns aspectos do jiu jitsu tradicional e fundar um novo
          estilo de lutar: o brazilian jiu jitsu, que privilegiava a luta no
          chão, seguida por golpes de finalização, ao contrário do jiu jitsu
          tradicional que mantinha seu foco nas quedas.
        </p>
        <p>
          Atualmente, o jiu jitsu é um esporte reconhecido e regulamentado, em
          nosso país, pela Confederação Brasileira de Jiu Jitsu. Em nível
          regional, há as federações estaduais. As principais competições
          nacionais são o campeonato brasileiro, o campeonato brasileiro de
          equipes e o campeonato brasileiro de estreantes. Outro papel da
          confederação brasileira é o de selecionar atletas para competirem em
          nível internacional, como o campeonato pan-americano e o campeonato
          mundial.
        </p>
        <p>
          Os níveis de desenvolvimento de um atleta são representados pela cor
          da faixa que amarra o seu quimono. Para atletas adultos, as cores são:
          branca, azul, roxa, marrom, preta, coral e vermelha, em ordem
          crescente de habilidade. Já no que se refere às crianças, como modo de
          incentivo, há a inclusão de outras três cores de faixa, localizadas
          entre a branca e a azul, são elas: amarela, laranja e verde.
        </p>

        <h2>Sobre as regras:</h2>

        <p>
          • A área de disputa entre dois atletas, chamada de ringue, tem área
          variável entre 64 e 100 metros quadrados. É restrita uma área menor
          para o combate, de modo que reste uma parte específica denominada de
          área de segurança. A cor do tatame da área de segurança é diferente da
          cor do tatame voltado à área de combate;
        </p>

        <p>
          • Antes de iniciar uma luta, os atletas devem passar por uma
          supervisão da organização do campeonato. Sua função é a de verificar
          se as unhas estão curtas, qual a cor da faixa e se o quimono está em
          condições ideais para a realização do combate;
        </p>

        <p>
          • Vence uma luta, o atleta que finalizar o outro. No entanto, há casos
          de combates em que isso não ocorre. Nesse caso, são contabilizados os
          movimentos de cada atleta e é feito uma somatória dessa pontuação. É
          considerado o vencedor aquele que somar mais pontos durante a luta.
        </p>

        <p>
          Para saber mais:
          <br />
          Confederação Brasileira de Jiu Jitsu –{" "}
          <a href="www.cbjj.com.br" target="_blank" rel="noreferrer">
            <u>www.cbjj.com.br</u>
          </a>
        </p>

        <p>
          Referência:
          <br />
          <a
            href="https://brasilescola.uol.com.br/educacao-fisica/jiu-jitsu.htm"
            target="_blank"
            rel="noreferrer"
          >
            <u>https://brasilescola.uol.com.br/educacao-fisica/jiu-jitsu.htm</u>
          </a>
        </p>
      </>
    ),
  },
};

export default JiuJitsu;
