interface Breakpoints {
  [key: string]: string;
}

export const breakpoints: Breakpoints = {
  xxs: "320px",
  xs: "360px",
  sm: "768px",
  md: "1024px",
  lg: "1366px",
  xl: "1440px",
  xxl: "1920px",
};

export default breakpoints;
