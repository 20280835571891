import styled from "styled-components";

export const MobileMenuWrapper = styled.div<{ isOpen: boolean }>`
  display: none;
  @media (max-width: 1440px) {
    display: flex;
    left: 0;
    top: 0;
    border: none;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    z-index: 999999999;
  }
`;

export const HamburgerButton = styled.button<{ isOpen: boolean }>`
  display: none;
  @media (max-width: 1440px) {
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
    border: none;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    z-index: 999999999;
  }
`;

export const Menu = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 90px 20px 0;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 999999998;

  @media (max-width: 1440px) {
    width: 100%;
  }
`;

export const MenuItem = styled.div`
  padding: 12px 0;
  width: 100%;
  text-align: center;
  /* border: 0.1px solid rgba(200, 200, 200, 0.1); */
  a {
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    &:hover {
      color: #fff;
    }
  }
`;

export const SubMenu = styled.ul<{ isSubmenuOpen: boolean }>`
  list-style: none;
  margin: 0 auto;
  padding: 10px 0;
`;
