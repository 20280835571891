import Article from "Components/Article/Article";
import React from "react";
import { ArticleProps } from "Components/Article/Article";
import Clayton from "Images/articles/headers/011.jpg";
import CMT12 from "Images/articles/headers/003.jpg";
import Equipe from "Images/articles/headers/013.jpg";
import Social from "Images/articles/headers/014.jpg";
import { ArticleSection, Hero, TrainingToHome } from "Utils/Styles/App.styled";
import { FaSchool, FaUsers, FaIdCard, FaTrophy } from "react-icons/fa";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import GoogleMapsEmbed from "Components/GoogleMapsEmbed/GoogleMapsEmbed";
import WhatsAppWazeButton from "Components/WhatsAppWazeButton/WhatsAppWazeButton";
import TrainingPage from "./treinos/treinos";

export interface HomeProps {
  titleBar?: TitleBarProps;
  trainingsTitleBar?: TitleBarProps;
  article1?: ArticleProps;
  article2?: ArticleProps;
  article3?: ArticleProps;
  article4?: ArticleProps;
}

const HomePage: React.FC<HomeProps> = (props) => {
  return (
    <>
      <Hero>
        <TitleBarPage {...props.titleBar} />
      </Hero>

      <ArticleSection>
        <Article {...props.article1} />
        <Article {...props.article2} />
        <Article {...props.article3} />
        <Article {...props.article4} />
      </ArticleSection>

      <TrainingToHome>
        <TrainingPage showTitleBar={true} />
      </TrainingToHome>

      <WhatsAppWazeButton />

      <GoogleMapsEmbed />
    </>
  );
};

HomePage.defaultProps = {
  titleBar: {
    titleBarText: "CMT12!",
    subtitleBarText: "Seja bem vindo à melhor escola de lutas da região!",
    showBackground: false,
  },
  trainingsTitleBar: {
    titleBarText: "Teste",
    subtitleBarText: "",
    showTitleBar: true,
  },
  article1: {
    articleImage: CMT12,
    iconName: <FaSchool />,
    toptext: "A Escola CMT12",
    title: "A Escola CMT12:",
    subtitle: "Sobre a Escola de Lutas CMT12",
    description:
      "A Clayton Mangueira Team é a melhor escola de lutas da região. A CMT12 foi criada pelo Mestre Clayton Mangueira. Na CMT12 você prende e mantém seu condicionamento físico no Boxe, Muay Thai, MMA, Luta Livre, Luta Olímpica e Jiu Jitsu. Escola de lutas em Bangu, Rio de Janeiro. Situada na praça Guilherme da Silveira, em frente à estação de trem de nome homônimo.",
    readmore: "/sobre",
  },
  article2: {
    articleImage: Equipe,
    iconName: <FaUsers />,
    toptext: "Equipe CMT12",
    title: "Nossa Equipe",
    subtitle: "Veja quem faz parte da CMT12",
    description:
      "Conheça a nossa equipe. Alunos, professores, mestres, faixas pretas e futuras promessas. Aqui você encontra um resumo sobre cada professor, mestre e faixa preta. Conheça um pouco da CMT12.",
    readmore: "/equipe",
  },
  article3: {
    articleImage: Clayton,
    iconName: <FaIdCard />,
    toptext: "Clayton Mangueira",
    title: "Clayton Mangueira",
    subtitle: "Mestre em Muay Thai, Colecionador de Medalhas...",
    description:
      "Carlos Clayton Araújo de Souza é o nome dele. O apelido 'Mangueira' veio após um projeto social no bairro homônimo, na cidade do Rio de Janeiro, onde lecionava artes marciais para diversas crianças carentes da região...",
    readmore: "/clayton-mangueira",
  },
  article4: {
    articleImage: Social,
    iconName: <FaTrophy />,
    toptext: "Braço Social",
    title: "A Oportunidade Faz o Campeão",
    subtitle: "CMT12 transformando vidas por meio do esporte",
    description:
      "Projeto social onde o Mestre Clayton Mangueira ensina artes marciais a jovens carentes de várias comunidades, proporcionando condicionamento físico, atletismo e distância da marginalidade que assola suas regiões",
    readmore: "/braco-social",
  },
};

export default HomePage;
