import React from "react";

const ErrorPage: React.FC = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Esta página não existe</h1>
      </div>
    </>
  );
};

export default ErrorPage;
