// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "Pages/Home";
import AboutPage from "Pages/About";
import ContactPage from "Pages/Contato";
import ErrorPage from "Pages/ErrorPage";
import ClaytonPage from "Pages/Clayton-Mangueira";
import EquipPage from "Pages/Equipe";
import SocialPage from "Pages/BracoSocial";
import TrainingPage from "Pages/treinos/treinos";
import Boxe from "Pages/treinos/boxe";
import React from "react";
import Jiujitsu from "Pages/treinos/jiujitsu";
import Muaythai from "Pages/treinos/muaythai";
import Lutalivre from "Pages/treinos/lutalivre";
import Lutaolimpica from "Pages/treinos/lutaolimpica";
import Mma from "Pages/treinos/mma";

const RoutesComponent: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/sobre" element={<AboutPage />} />
        <Route path="/contato" element={<ContactPage />} />
        <Route path="/clayton-mangueira" element={<ClaytonPage />} />
        <Route path="/equipe" element={<EquipPage />} />
        <Route path="/braco-social" element={<SocialPage />} />
        <Route path="/treinos" element={<TrainingPage />} />
        <Route path="/treinos/boxe" element={<Boxe />} />
        <Route path="/treinos/jiujitsu" element={<Jiujitsu />} />
        <Route path="/treinos/muaythai" element={<Muaythai />} />
        <Route path="/treinos/lutalivre" element={<Lutalivre />} />
        <Route path="/treinos/mma" element={<Mma />} />
        <Route path="/treinos/lutaolimpica" element={<Lutaolimpica />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default RoutesComponent;
