import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import React from "react";
import TopImage from "Images/articles/headers/lutalivre.jpg";
import TrainingPage from "./treinos";

export interface LutalivreProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const Lutalivre: React.FC<LutalivreProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />

      <div style={{ marginBottom: "-240px", zIndex: 10 }}>
        <TrainingPage
          showTitleBar={false}
          activityToShow="Luta Livre"
          isReadmore={true}
          readmore={"#readmore"}
        />
      </div>

      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

Lutalivre.defaultProps = {
  titleBar: {
    titleBarText: "Luta Livre",
    subtitleBarText: "Treine Luta Livre na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  pageSection: {
    topImage: TopImage,
    showTopImage: false,
    pageTitle: "A História da Luta Livre",
    pageText: (
      <>
        <p>
          Todas as histórias que envolvem as origens das diversas Artes Marciais
          e Lutas (antigas), curiosamente se iniciam da mesma maneira. Assim,
          dizem os textos: “...A luta livre, a mais antiga de todas...”
        </p>
        <p>
          Aparecendo sempre um período de clandestinidade, de luta pela
          sobrevivência com todos os ingredientes de um épico, que consegue
          ultrapassar os séculos e chega até os dias de hoje. Tudo é muito
          parecido, sendo os relatos mais antigos da faixa de 3000 anos (1000
          AC).
        </p>
        <p>
          Com a Luta Livre, a origem é bem diferente (a única modalidade de luta
          que nasceu no ocidente), não existem regiões escondidas, príncipes e
          guerreiros e nem influências, inspirações etc. A história é muito mais
          simples. A Luta nasceu em cada lugar onde o homem apareceu. E junto
          com ele se desenvolveu. Ela nasce “livre” como diz o próprio nome sem
          adereços ou complementos, em consonância com a própria natureza. O
          homem sempre lutou, e continuará lutando até o fim dos séculos.
        </p>
        <p>
          No começo da história humana os homens primitivos, a semelhança dos
          animais também "brincavam" de lutar como até hoje fazem os filhotes
          dos felinos, por exemplo. Além do componente lúdico, existia também um
          aprendizado imprenscidível, a sobrevivência, onde os mestres eram
          aqueles que os haviam gerado. Portanto não pode haver dúvida que a
          LUTA é o mais antigo desporto e especificamente a Luta Livre, a mãe de
          todas as outras modalidades de combate corporal.
        </p>
        <p>
          Assim, em cada canto da terra a luta surgia, se adaptando as condições
          e costumes locais aos quais se rendia para poder sobreviver e
          continuar seu inexóral destino.
        </p>
        <p>
          A primeira menção escrita (em caracteres cuneiformes) que se tem
          registro, data da época dos Sumérios, aproximadamente 4000 AC onde no
          "Poema de Gilgamesh" um rei descreve uma luta em detalhes. Mais tarde
          no antigo Egito, nas tumbas da 5ª Dinastia 2470 AC, aparecem muitos
          desenhos de luta onde os modelos às vezes eram crianças, o que garante
          o componente esportivo e didático da atividade. E pasmem (!), as
          posições mostradas são muito parecidas com as de hoje!
        </p>
        <p>
          Outros monumentos como os túmulos de Beni Hassam (400), Bahti III
          (219) e Sethi (122) também mostram muitos desenhos até hoje
          preservados, de posições de Luta livre, nas quais até hoje usamos.
        </p>
        <p>
          Até na Bíblia existe menção à luta. No livro de (Gênesis 32: 24 e 25)
          , Jacó luta durante muito tempo com um adversário físico e meta físico
          (um anjo) que não o conseguindo vencer, então desloca a junta da coxa
          de Jacó . Também Moisés, que foi ensinado a lutar como príncipe, já
          que era príncipe do Egito, hà menção de que ele usou as técnicas da
          Luta livre, que lhe foi ensinado pelo exército de Faraó, as quais
          estão registradas nos hieroglifos egípcios nas tumbas dos faraós,
          pois, ele sozinho conseguiu, defender as filhas do seu futuro sogro,
          quando foram atacadas por “uns pastores” (Êxodo 2:16 ao 19).
        </p>
        <p>
          Na Grécia antiga, aconteceram tentativas de "criar" uma origem mística
          para a luta dizendo que ela tinha sido inventada por Herakles, Hermes
          e Palestra e então integrada aos Jogos Olímpicos. Naquela época não
          havia categoria de peso ou tempo de luta. Havia entretanto diversas
          modalidades, o Pancracio (considerada a mais violenta) e as Lutas, que
          se dividiam em 3 especialidades:
        </p>
        <p>
          • Ortopalio -luta mais aceita nos antigos Jogos Olímpicos que dava a
          vitória aquele que tombasse três vezes o adversário.
        </p>
        <p>
          • Alindissis - Especialidade praticada nos treinamentos, semelhante à
          Luta Livre de hoje. Terminava com desistência de um dos lutadores.
        </p>
        <p>
          • Acrocrismos - Um tipo de luta de pegada de dedos que provocava
          muitas contusões. Como curiosidade, é impressionante como alguns
          artistas da época mostravam na pintura e especialmente nas esculturas
          com meticulosa precisão as lesões resultantes das lutas, no nariz, nas
          orelhas etc.
        </p>

        <p>
          A "Lex Aquilia" do estado Romano em 150 AC condenava ao culpado a
          ressarcir pelos danos causados ao outro atleta no combate. Da mesma
          forma penalizava com rigor os que tentavam causar sérios danos ou a
          morte ao adversário.
        </p>
        <p>
          Vale lembrar que sempre houve um estreito relacionamento entre o
          desenvolvimento da luta e o desenvolvimento sócio-econômico e político
          das regiões. Por outro lado, a própria vontade de domínio individual e
          auto-superação sempre alavancaram de alguma forma o desenvolvimento da
          luta.
        </p>
        <p>
          Seria oportuno lembrar que a origem da Luta Livre, se funde com a da
          Luta Olímpica (Wrestling), especialidade que hoje tem sem dúvida o
          maior número de praticantes no mundo (mais de 30.000.000) entre todas
          as lutas genericamente falando (artes marciais). Sem dúvida a maior e
          mais consistente bibliografia, onde aparecem as pesquisas mais
          confiáveis, os trabalhos com o melhor embasamento técnico etc. são
          referentes a Luta Olímpica, tudo graças as excelente apoio dos órgãos
          dirigentes da modalidade, comandados pela Federação Internacional de
          Luta e Associadas (FILA) com sede em Lausanne na Suíça, que serve de
          exemplo para as outras modalidades.
        </p>
        <p>
          Dada a múltipla origem da luta, sempre ligada ao próprio nascimento
          das diversas civilizações, existe uma centena de lendas e histórias,
          que já fazem parte do folclore de cada povo.
        </p>
        <p>
          O Brasil também tem a sua luta raiz, o "Uka-Uka" dos índios nativos,
          que faz parte da tradicional festa dos índios da tribo Xavante, o
          "Quarup". A partir de 776 AC, na Grécia a luta se tornou parte central
          dos Jogos Olímpicos e lá surgiram papiros que definiam os
          procedimentos da luta etc. Tal era a popularidade da luta que em quase
          todas as escolas havia um escrito "Primeiro aprender a lutar na
          Escola, depois entre em Competições".
        </p>
        <p>
          Em Roma a luta nasceu como herança dos antigos Etruscos e da
          influência dos Gregos. Estava bem claro que os romanos preferiam as
          facetas mais violentas da luta, como o “Pancratium” (mencionados
          anteriormente), e a luta entre os gladiadores, mas ela servia como
          preparo para os soldados e gladiadores. Da mesma forma quando era
          necessário escolher os líderes da tropa a luta era escolhida como
          critério através de uma competição.
        </p>
        <p>
          No Japão, o Sumo é considerada a luta mais antiga, havendo registros
          do início da era Cristã de formidáveis competições cujos campeões são
          lembrados até hoje, como patronos da modalidade.
        </p>
        <p>
          No Irã e na Turquia havia uma enorme variedade de lutas e seus
          campeões eram considerados heróis, usufruindo de grandes prestígios na
          comunidade. Antes do século XV já havia escolas de lutas que ensinavam
          métodos definidos, e também grandes torneios com centenas de
          participantes. Mas, com tudo, não escapou a regra geral: período de
          “formação”, “apogeu” e “decadência”.
        </p>
        <p>
          Na Ásia pode se considerar que o desenvolvimento se iniciou por volta
          do ano 200 da era Cristã, especialmente na China e na Mongólia, onde a
          semente germinou tornando-se matéria obrigatória nas escolas à partir
          do século III. Na Índia, os épicos hindus “ramayana” e “mahabbarata”
          testemunharam a prática da luta. Até Buda, teria lutado pela bela
          princesa Gopa. Na Rússia, a partir do ano 1000 existem muitos
          registros e no resto da Europa, sempre apoiado nas lutas raízes ela
          foi se desenvolvendo em praticamente todas as regiões, surgindo na
          segunda metade do século XV os primeiros “manuais de luta”, publicados
          na Alemanha em 1443. Posteriormente, em 1539, na cidade de Willenberg,
          Fabien Von Auerswald escreveu “A Arte Da Luta”.
        </p>
        <p>
          Além dos escritores aficionados havia naquele período, excelentes
          pintores de prestígio que fizeram dezenas de ensaios e pinturas,
          mostrando didaticamente, posições de luta.
        </p>
        <p>
          Na época feudal, a luta se desenvolveu seletivamente, pois apenas
          aqueles ligados aos senhores tinham condições de praticá-la.
        </p>
        <p>
          Finalmente no início do século XX a luta se tornou o esporte mais
          popular do mundo. Daí em diante aquela fase de íntima ligação das
          Lutas Olímpicas e da “nossa” Luta Livre, começou a se dissipar.
        </p>
        <p>
          A Luta (Submission Grappling), praticamente foi amassada pelo imenso
          sucesso das “Olímpicas”, tendo vivido no mundo alternativo durante
          muito tempo. Naquele mundo se apoiou também nos praticantes da chamada
          Luta Profissional (Catch, Pró Wrestling etc.) a famosa “marmelada” e
          de amadores das lutas de kimono que eventualmente treinavam sem o
          “paletó”. Um dos maiores centros de prática da luta era o Brasil, onde
          entretanto, não havia aquelas condições necessárias ao perfeito
          desenvolvimento. As condições são bastante claras e simples: existirem
          pessoas que possam viver da luta e haver competições. Mesmo assim, as
          coisas caminharam e o Brasil se tornou uma força na Luta Mundial,
          embora até hoje sem a organização que se faria necessário. Japão e
          Estados Unidos, caminharam juntos também, sem entretanto atingir o
          nível do Brasil. Um dos fatores que mais alavancaram a Luta, foi o
          ressurgimento do “Vale Tudo” (“No Holds Barred” ou “Mixed Martial
          Arts”) com diversos eventos importantes no Brasil, Estados Unidos e
          Japão. No Brasil, observei que até 1960 o conhecimento da luta livre,
          era incipiente ou deformado, pois, mais de 80% dos praticantes era
          halterofilista ou remador. Só eram conhecidas umas poucas técnicas
          como chaves de braço, gravatas de frente (atualmente guilhotina) etc.
          Em vinte anos ficou patente que a situação mudou e novas escolas
          surgiram, com novos métodos de ensino. A “inteligência”, a
          “habilidade”e a verdadeira técnica começaram a ocupar o seu espaço, e
          naquela oportunidade eu graças a minha compleição física (pequeno,
          leve, embora forte), tive a imensa felicidade de poder colaborar
          efetivamente com aquele desenvolvimento. O “truque” foi enfrentar os
          fisicamente privilegiados com sucesso, pois os praticantes daquela
          época só entendiam a linguagem “do fazer”. Por outro lado, aquela fase
          foi um excelente laboratório que dá frutos até hoje. Mais dez anos se
          passou e o Brasil começou a exportar seus talentos, sendo o grande
          catalisador do desenvolvimento dos outros centros.
        </p>
        <p>
          A Luta Livre nestes últimos 10 anos cresceu em técnica, reduzindo o
          impacto da força e condicionamento físico, conseqüentemente aumentando
          o seu refinamento, exatamente no sentido contrário do que aconteceu
          com outras modalidades, especialmente praticada com kimono (jiu-jitsu,
          judô). Para comprovar é só conhecer os campeões atuais daquelas
          modalidades e constatar a compleição física de cada um e como eles
          atuam. A história de “arte suave”, “caminho suave” já ficou para trás
          e os próprios dirigentes confirmam o que estou dizendo ao classificar
          de “máster” um atleta de apenas 30 anos (!).
        </p>
        <p>
          Felizmente na Luta Livre, a história é outra, cada vez mais os
          praticantes se interessam pela técnica, procurando se apoiar nela para
          poder enfrentar aqueles que se apóiam exclusivamente no vigor físico e
          na violência...
        </p>
        <p>Pesquisa feita pelo Grão Mestre de LUTA-LIVRE Roberto Leitão.</p>
        <p>
          Referência:
          <br />
          <a
            href="http://www.lutalivresubmission.com.br/origem.htm"
            target="_blank"
            rel="noreferrer"
          >
            <u>http://www.lutalivresubmission.com.br/origem.htm</u>
          </a>
        </p>
      </>
    ),
  },
};

export default Lutalivre;
