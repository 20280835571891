import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import TopImage from "Images/articles/headers/clayton-1.jpg";
import VideoEmbed from "Components/VideoEmbed/VideoEmbed";
import { VideoEmbedSection } from "Utils/Styles/App.styled";

export interface ClaytonPageProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const ClaytonPage: React.FC<ClaytonPageProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />
      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

ClaytonPage.defaultProps = {
  titleBar: {
    titleBarText: "QUEM É CLAYTON MANGUEIRA?",
    subtitleBarText:
      "Mestre em Muay Thai, Colecionador de Medalhas... e muito mais!",
  },
  pageSection: {
    topImage: TopImage,
    pageTitle: "Carlos Clayton Araújo de Souza",
    pageText: (
      <>
        <p>
          Este é o nome dele. O apelido "Mangueira" veio após um projeto social
          no bairro homônimo, na cidade do Rio de Janeiro, onde lecionava artes
          marciais para diversas crianças carentes da região.
          <br />
          O saudoso Grão Mestre Luiz Alves “In Memoriam” sempre o chamava de
          “Mangueira” quando chegava para treinar na academia BUDOKAN, fixando
          então, o apelido. Nascido na década de 70, na maternidade Carmela
          Dutra na cidade do Rio de Janeiro.
          <br />
          <br />
          <br />
          <b>Por que o número 12?</b>
          <br />
          O número surgiu na criação da equipe Clayton Mangueira Team, em 1998.
          <br />
          A letra M foi incluída na sua insígnia CMT12 em seus uniformes de
          competição.
          <br />
          O número 12 equivale à posição da letra M no alfabeto.
          <br />
          <br />
          <b>Participações na TV/Cinema:</b>
          <br />
          Seriado Bom de Briga - Canal Space
          <br />
          Documentário O Infiltrado no MMA - Canal History Channel
          <br />
          Filme Tropa de Elite - 2007
          <br />
          Novela Orgulho e Paixão - Tv Globo
          <br />
          <br />
          <br />
          <b>Títulos:</b>
          <br />
          Colecionador de medalhas, Mangueira é bicampeão do ranking mundial da
          IBJJF 2018/2019 na modalidade no-gi (sem kimono) e detém diversas
          outras conquistas. Veja:
          <br />
          <br />
          <b>IBJJF JIU-JITSU NO GI (sem kimono):</b>
          <br />
          Campeão Mundial: 2019
          <br />
          Campeão Mundial: 2018
          <br />
          Campeão Sul-brasileiro - 2019 (Peso e absoluto)
          <br />
          Campeão Sul-americano - 2019 (Absoluto)
          <br />
          Campeão Belo Horizonte Winter Internacional - 2018 (Peso e Absoluto)
          <br />
          Campeão Belo Horizonte Winter Internacional - 2019 (Peso e Absoluto)
          <br />
          Campeão Belo Horizonte Springer - 2018 (Peso e Absoluto)
          <br />
          Campeão do Brasília Open Internacional - 2018 (Absoluto)
          <br />
          Campeão Curitiba Summer Internacional - 2018
          <br />
          Campeão Curitiba Summer Internacional - 2019 (Absoluto)
          <br />
          Campeão Curitiba Fall - 2018
          <br />
          Campeão do Curitiba Springer - 2018
          <br />
          Bicampeão do Floripa Fall Internacional - 2018
          <br />
          Bicampeão do Floripa Fall Internacional - 2019 (Absoluto)
          <br />
          Campeão do Floripa Winter - 2019 (Peso e Absoluto)
          <br />
          Campeão Fortaleza Internacional - 2019 (Peso e Absoluto)
          <br />
          Campeão Rio Internacional - 2018
          <br />
          Campeão do Rio Fall - 2019 (Peso e Absoluto)
          <br />
          Bicampeão Salvador Fall Internacional - 2018
          <br />
          Bicampeão Salvador Fall Internacional - 2019 (Absoluto)
          <br />
          Bicampeão Salvador Springer - 2018 (Peso e Absoluto)
          <br />
          Bicampeão Salvador Springer - 2019 (Peso e Absoluto)
          <br />
          Campeão São Paulo Internacional - 2018 (Absoluto)
          <br />
          Campeão Vitória Open - 2018
          <br />
          Bicampeão Vitória Open - 2018 (Absoluto)
          <br />
          Bicampeão Vitória Open - 2019 (Absoluto)
          <br />
          <br />
          <br />
          <b>LUTA LIVRE ESPORTIVA:</b>
          <br />
          Tricampeão Estadual
          <br />
          Bicampeão da Copa BUDOKAN
          <br />
          Campeão Interestadual
          <br />
          Campeão Brasileiro
          <br />
          <br />
          <br />
          <b>Participação no ADCC 2000 (Abu Dhabi Championships)</b>
          <br />
          <br />
          <b>LUTA OLÍMPICA:</b>
          <br />
          Campeão Estadual: 2001 - 2002 - 2003 - 2004 - 2005 - 2006 - 2007{" "}
          <br />
          Campeão Brasileiro: 2002 - 2003 - 2004 - 2005 - 2006 <br />
          Campeão em Portugal: 2003 <br />
          Campeão na Argentina: 2005 <br />
          Copa CBLA: 2002 - 2003 - 2004 <br />
          Número 1 do ranking: 2002 - 2003 - 2004 - 2005 - 2006 - 2007 - 2008{" "}
          <br />
          Atleta da Seleção Brasileira Panamericana na Guatemala: 2003 - 2005{" "}
          <br />
          <br />
          <br />
          <b>MUAY THAI:</b>
          <br />
          Mestre de Muay Thai pela Confederação Brasileira de Muay Thai.
          <br />
          Delegado Nacional pela Confederação Brasileira de Muay Thai.
          <br />
          Campeão da Copa Marcos Ruas
          <br />
          Campeão Estadual
          <br />
          Campeão Brasileiro
          <br />
          <br />
          <br />
          <b>MMA:</b>
          <br />
          Campeão do Meca WORLD 1<br />
          Campeão do Meriti Open Fight
          <br />
          Campeão Top Fight
          <br />
          Campeão do Detonação Fight
          <br />
          Campeão do Rio MMA CHALLENGE
          <br />
          <br />
          <br />
          <b>GRADUAÇÃO:</b>
          <br />
          Treinador de Boxe pela FBERJ (Federação de Boxe do Estado do Rio de
          Janeiro)
          <br />
          Treinador de Beachboxing pela FEBOP (Federação de Boxe de praia do Rio
          de Janeiro)
          <br />
          Treinador de Luta Olímpica.
          <br />
          Treinador de MMA. Faixa Preta de LUTA LIVRE 5° Dan
          <br />
          Faixa Preta de Jiu-Jitsu
          <br />
          Grau Preto de Muay Thai
          <br />
          <br />
          <br />
          <b>FORMAÇÃO:</b>
          <br />
          Bacharel em Educação Física - Universidade Gama Filho - Cref 007456/RJ
          <br />
          Pós Graduado em Gestão de Negócios em Fitness e Wellness - Univoa
          <br />
          <br />
        </p>
        <VideoEmbedSection>
          <VideoEmbed videoId="n16HUzLxzaI" isVideo={true} />
          <VideoEmbed videoId="7rDvg4cxjVc" isVideo={true} />
          <VideoEmbed videoId="qylJUOwZDs8" isVideo={true} />
          <VideoEmbed videoId="MRnc3NixOCs" isVideo={true} />
        </VideoEmbedSection>
      </>
    ),
  },
};

export default ClaytonPage;
