import styled from "styled-components";
import * as color from "./Colors";
import * as space from "./Spaces";
import { HeaderProps } from "Components/Header/Header";
import * as icon from "react-icons/fa";
import { up } from "Utils/Helpers/Media";

export const HeaderMain = styled.div`
  width: 100%;
  height: 110px;
  background-color: ${color.black};
  display: flex;
  top: 0;
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2em;
  z-index: 9999;
  ${up("xxs")} {
    display: none;
  }
  ${up("xl")} {
    display: flex;
  }
`;

export const HeaderLeft = styled.div`
  align-self: center;
  padding-left: ${space.BodyPaddingLeft};
  left: 0;
  position: absolute;
  /* background-color: red; */
  z-index: 9999;
  img {
    max-width: 140px;
  }

  ${up("xl")} {
    //1440
  }
`;

export const HeaderRight = styled.div`
  /* background-color: red; */
  right: 0;
  position: absolute;
  /* width: 100%; */
  /* justify-content: flex-start; */
  padding-right: ${space.BodyPaddingRight};

  ${up("xl")} {
    //1440
  }
`;

export const HeaderUL = styled.ul`
  display: flex;
  list-style: none;
  gap: 60px;
  align-items: center;
  position: relative;
`;

export const HeaderLI = styled.li`
  cursor: pointer;
  align-self: center;
`;

export const SubmenuArrowRight = styled(icon.FaCaretRight)`
  font-size: 0.8em;
  color: ${color.white};
  margin-left: 4px;
`;

export const SubmenuArrowDown = styled(icon.FaCaretDown)`
  font-size: 0.8em;
  color: ${color.white};
  margin-left: 4px;
`;

export const Submenu = styled.ul<HeaderProps>`
  z-index: 999999;
  position: absolute;
  top: 200%;
  width: 200px;
  padding: 10px;
  background-color: ${color.black};
  list-style: none;
  margin: 0;
  padding: 0;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
`;

export const MenuItem = styled.li`
  cursor: pointer;
  padding: 15px;
`;
