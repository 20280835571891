import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import TopImage from "Images/articles/headers/014.jpg";
import React from "react";

export interface SocialPageProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const SocialPage: React.FC<SocialPageProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />
      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

SocialPage.defaultProps = {
  titleBar: {
    titleBarText: "Projeto Social",
    subtitleBarText: "A Oportunidade Faz o Campeão",
  },
  pageSection: {
    topImage: TopImage,
    pageTitle: "A Oportunidade Faz o Campeão",
    pageText: (
      <>
        <p>
          A construção desta página está sendo concluída.
          <br />
          Em breve teremos um conteúdo aqui.
          <br />
        </p>
      </>
    ),
  },
};

export default SocialPage;
