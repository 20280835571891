import PageSection, {
  PageSectionProps,
} from "Components/PageElements/PageSection";
import TitleBarPage, {
  TitleBarProps,
} from "Components/PageElements/PageTitleBar";
import React from "react";
import TopImage from "Images/articles/headers/muaythai.jpg";
import TrainingPage from "./treinos";

export interface MuaythaiProps {
  titleBar?: TitleBarProps;
  pageSection?: PageSectionProps;
}

const Muaythai: React.FC<MuaythaiProps> = (props) => {
  return (
    <>
      <TitleBarPage {...props.titleBar} />

      <div style={{ marginBottom: "-240px", zIndex: 10 }}>
        <TrainingPage
          showTitleBar={false}
          activityToShow="Muay Thai"
          isReadmore={true}
          readmore={"#readmore"}
        />
      </div>

      <PageSection {...props.pageSection}></PageSection>
    </>
  );
};

Muaythai.defaultProps = {
  titleBar: {
    titleBarText: "Muay Thai",
    subtitleBarText: "Treine Muay Thai na CMT12",
    showBackground: true,
    showTitleBar: true,
  },
  pageSection: {
    topImage: TopImage,
    showTopImage: false,
    pageTitle: "A História do Muay Thai",
    pageText: (
      <>
        <p>
          O Muay Thai, também conhecido como boxe Tailandês, é um esporte
          originado na Tailândia, com mais de 1000 anos de existência. O esporte
          teve muita popularidade, pois na época em que começou teve o apoio do
          rei da Tailândia, conhecido como Tigre, o mesmo foi um dos maiores
          lutadores da história da Tailândia, e por sua popularidade o
          treinamento também era ensinado nas escolas, além de ser obrigatórios
          para os soldados. Já no Brasil o Muay Thai chegou no ano de 1979. Em
          1980 foi inaugurado a primeira associação e em 1981 foi disputado o
          primeiro campeonato entre o Rio de Janeiro e o Paraná.
        </p>
        <p>
          Conhecido como um esporte de combate muito violento, com o uso dos
          cotovelos, joelhos, golpes com a canela, chutes e também golpes
          giratórios.
        </p>
        <p>
          As Cotoveladas podem ser usadas em diversos ângulos, como na diagonal,
          para cima ou para baixo. Esse golpe é mais usado quando a distância
          para o adversário é pequena.
        </p>
        <p>
          Os Joelhos são usados em 4 golpes diferentes: kao dode - pulo para
          cima e com a mesma perna é dado um golpe com o joelho; kao loi - pulo
          para o lado e com a mesma perna se da à joelhada; kao tom - joelhada
          para cima em linha reta e o kao noi onde a joelhada busca a coxa ou a
          barriga do adversário.
        </p>
        <p>
          Os socos também são utilizados de 4 formas: jab - soco com a mão da
          frente buscando o queixo do adversário; o direto é com a mão de trás
          buscando também o queixo; cruzado é o que cruza a linha frontal do
          adversário e o upper é o soco de baixo para cima buscando o queixo.
        </p>
        <p>
          Os chutes no muay thai são utilizados com o pé ou com a canela e
          existem 3 formas: round kick o chute circular que busca a coxa, canela
          ou cabeça, front kick é o chute frontal que busca a defesa de um golpe
          e já preparar para o ataque, spin back kick com um giro acerta o
          adversário com o calcanhar.
        </p>
        <p>
          Esta arte tem técnicas de ataque e defesa, e é um tipo de arte marcial
          onde existe muito atrito com o adversário.
        </p>
        <p>
          Nos tempos em que o Muay Thai surgiu na Tailândia, os monges budistas
          tinham a tradição de tatuar o corpo para se ter a proteção divina, e
          com isso também acreditam que têm a capacidade de conseguir a
          admiração dos adversários. E esta tradição também existe no Brasil, a
          grande maioria dos lutadores tem tatuagens pelo corpo. O simbolo no
          muay thai é a cobra Naja, pois como a Naja os lutadores devem ter um
          bote veloz e preciso e também bom reflexo.
        </p>
        <p>
          Referência:
          <br />
          <a
            href="https://www.infoescola.com/artes-marciais/muay-thai/"
            target="_blank"
            rel="noreferrer"
          >
            <u>https://www.infoescola.com/artes-marciais/muay-thai/</u>
          </a>
        </p>
      </>
    ),
  },
};

export default Muaythai;
