import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  HamburgerButton,
  Menu,
  MenuItem,
  MobileMenuWrapper,
  SubMenu,
} from "./MobileMenu.styled";
import { FaBars, FaTimes, FaCaretDown, FaCaretUp } from "react-icons/fa";
import LogoH from "Images/logo-topo-bco.png";

const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  const toggleClose = () => {
    setIsOpen(false);
    setSubmenuOpen(false);
  };

  return (
    <MobileMenuWrapper isOpen={isOpen}>
      <HamburgerButton onClick={toggleMenu} isOpen={isOpen}>
        <div>
          <img
            style={{
              maxWidth: "80px",
              padding: "0 2em",
            }}
            src={LogoH}
            alt="CMT12 Logo"
          />
        </div>
        <div>
          {!isOpen ? (
            <FaBars
              style={{
                color: "white",
                fontSize: "2em",
                padding: "0 2em",
              }}
            />
          ) : (
            <FaTimes
              style={{
                color: "white",
                fontSize: "2em",
                padding: "0 2em",
              }}
            />
          )}
        </div>
      </HamburgerButton>

      {isOpen && (
        <Menu isOpen={isOpen}>
          <MenuItem>
            <NavLink onClick={toggleClose} to="/">
              Home
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink onClick={toggleClose} to="/sobre">
              Sobre
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink onClick={toggleClose} to="/clayton-mangueira">
              Clayton Mangueira
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink onClick={toggleClose} to="/equipe">
              Nossa Equipe
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink onClick={toggleClose} to="/braco-social">
              Braço Social
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={"#"} onClick={toggleSubmenu}>
              Treinos
              {!isSubmenuOpen ? (
                <FaCaretDown
                  style={{
                    color: "white",
                    fontSize: "1em",
                    padding: "0 0.4em",
                  }}
                />
              ) : (
                <FaCaretUp
                  style={{
                    color: "white",
                    fontSize: "1em",
                    padding: "0 0.4em",
                  }}
                />
              )}
            </NavLink>
            {isSubmenuOpen && (
              <SubMenu isSubmenuOpen={isSubmenuOpen}>
                <MenuItem>
                  <NavLink onClick={toggleClose} to="/treinos/boxe">
                    Boxe
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink onClick={toggleClose} to="/treinos/jiujitsu">
                    Jiu Jitsu
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink onClick={toggleClose} to="/treinos/muaythai">
                    Muay Thai
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink onClick={toggleClose} to="/treinos/lutalivre">
                    Luta Livre
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink onClick={toggleClose} to="/treinos/mma">
                    MMA
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink onClick={toggleClose} to="/treinos/lutaolimpica">
                    Luta Olímpica
                  </NavLink>
                </MenuItem>
              </SubMenu>
            )}
          </MenuItem>
          <MenuItem>
            <NavLink onClick={toggleClose} to="/contato">
              Contato
            </NavLink>
          </MenuItem>
        </Menu>
      )}
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
