import styled from "styled-components";
import * as color from "Utils/Styles/Colors";

export const ContactFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.4em;
  gap: 10px;
  border-radius: 10px;
  font-family: inherit;
`;

export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: inherit;

  label {
    padding-left: 10px;
    height: 40px;
    font-family: inherit;
  }

  input {
    width: 400px;
    font-size: 0.7em;
    border-radius: 20px;
    border: none;
    margin-top: 5px;
    padding: 15px;
    font-family: inherit;
  }

  textarea {
    width: 400px;
    height: 150px;
    font-size: 0.7em;
    border-radius: 20px;
    border: none;
    margin-top: 5px;
    padding: 15px;
    resize: none;
    font-family: inherit;
  }

  button {
    background: ${color.green};
    color: ${color.white};
    width: 400px;
    height: 50px;
    border-radius: 20px;
    border: none;
    padding: 15px;
    cursor: pointer;
    margin-top: 15px;
    font-family: inherit;
  }

  button:hover {
    background: ${color.darkGreen};
  }

  ul {
    list-style: square;
  }

  ul li {
    cursor: pointer;
  }
`;
